/* Export mode container styles */
.export-mode.react-flow {
    background-color: white !important; /* Clean white background */
    border: none !important; /* Remove outer border */
    box-shadow: none !important; /* No shadows */
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .export-mode .react-flow__renderer {
    overflow: visible !important; /* Ensure content is visible */
  }
  
  /* Nodes in export mode */
  .export-mode .react-flow__node {
    box-shadow: none !important; /* Remove shadows */
    border: 1px solid #000 !important; /* Black border for nodes */
    background-color: #fff !important; /* Consistent white background */
    color: #000 !important; /* Black text */
    z-index: 10; /* Ensure nodes are above edges */
  }
  
  /* Edges in export mode */
  .export-mode .react-flow__edge {
    stroke: black !important; /* Solid black color for edges */
    stroke-width: 2px !important; /* Thicker edges for visibility */
    z-index: 5 !important; /* Ensure edges are below nodes */
  }
  
  .export-mode .react-flow__edge-path {
    stroke: black !important; /* Ensure edge path color is black */
    stroke-width: 2px !important; 
  }
  
  
  /* Edge labels in export mode */
  .export-mode .react-flow__edge-text {
    background: none !important; /* Remove background from labels */
    color: #000 !important; /* Black text for labels */
    font-size: 12px !important; /* Adjust label font size */
  }
  
  /* Group containers in export mode */
  .export-mode .react-flow__node-group {
    background: #fff !important; /* Ensure consistent white background */
    border: 1px solid #000 !important; /* Black border */
  }
  
  /* General overflow adjustment */
  .export-mode * {
    overflow: visible !important;
  }
  .export-mode .react-flow__zoompane {
    cursor: default !important; /* Neutral cursor */
  }
  
  .export-mode .react-flow__selectionpane {
    display: none !important; /* Hide selection overlays */
  }
  
  .export-mode .react-flow__minimap {
    background-color: white !important; /* White background for minimap */
    border: 1px solid black !important; /* Border around the minimap */
  }
  
  .export-mode .react-flow__minimap .react-flow__node {
    fill: black !important; /* Black nodes in the minimap */
  }
  .export-mode .react-flow__controls {
    display: none !important; /* Hide controls during export */
  }
  .export-mode .react-flow__handle {
    width: 12px; /* Ensure consistent size */
    height: 12px;
    background-color: black !important; /* Black connectors */
    border: 1px solid #000 !important; /* Clean border */
    box-shadow: none !important; /* No shadows */
  }
  
  .export-mode .react-flow__handle-top,
  .export-mode .react-flow__handle-right,
  .export-mode .react-flow__handle-bottom,
  .export-mode .react-flow__handle-left {
    background-color: black !important; /* Consistent color for all positions */
  }
      